<template>
    <div class="p-grid crud-demo">
        <Loader v-model="loading" />
        <div class="col-12">
            <div class="card">
                <HelperDialog v-model="CustomerHelper" header="Buscar Cliente" :headers="CustomerHeaders" :rows="Customers" @selected="selectCustomer" />
                <HelperDialog v-model="CancelationRelationHelper" header="Buscar Recibo Electorico a Relacionar" :headers="adminHeaders" :rows="ReceiptPayments" @selected="selectNewReceipt" />
                <Email @error="errorEmail"  @sended="sendedEmail" v-model="emailDialog" :document="receipt_to_email.id" :title="receipt_to_email.id" :key_name="'ENVIO_FACTURAS'" :to="receipt_to_email.email"  />
                <Panel header="Parametros de Busqueda">
                    <BasicFormToolbar v-if="!view" @list="openTable" :actions="['list']">
                    </BasicFormToolbar>
                    <div class="grid formgrid p-fluid">
                        <div class="col-12">
                            <Fieldset :toggleable="true" legend="Informacion General">
                                <div class="p-fluid formgrid grid">
                                    <FormCalendar
                                        wrapperClass="field col-12 md:col-3 xl:col-3" 
                                        label="Fecha Inicio" dateFormat="dd/mm/yy"
                                        v-model="initial_date"/>
                                    <FormCalendar
                                        wrapperClass="field col-12 md:col-3 xl:col-3" 
                                        label="Fecha Inicio" dateFormat="dd/mm/yy"
                                        v-model="final_date"/>
                                    <FormInputNumber
                                        label="Folio"
                                        wrapperClass="field col-12 md:col-3 xl:col-3"
                                        v-model="Folio"/>
                                    <FormInputText
                                        label="Cliente"
                                        wrapperClass="field col-12 md:col-3 xl:col-3"
                                        v-model="Customer" @search="(CustomerHelper.visible = true)"
                                        :search="true" :readonly="true"/>
                                    <FormInputText
                                        label="RFC Cliente"
                                        wrapperClass="field col-12 md:col-3 xl:col-3"
                                        v-model="Customer_rfc"/>
                                    <FormDropdownComplex wrapperClass="field col-12 md:col-3 xl:col-3"  
                                        :options="c_Moneda" optionLabel="Descripcion" optionValue="c_Moneda" 
                                        :label="'Moneda'" :labelInOption="'c_Moneda - Descripcion'" 
                                        :labelInValue="'c_Moneda - Descripcion'" :filter="true" 
                                        tooltip="Se obtiene del catalogo del SAT: Moneda"
                                        v-model="Currency"/>
                                    <FormInputNumber
                                        label="Folio del deposito"
                                        wrapperClass="field col-12 md:col-3 xl:col-3"
                                        v-model="Folio_Dep"/>
                                    <FormDropdownComplex wrapperClass="field col-12 md:col-3 xl:col-3"
                                        label="Estatus" :options="STATUS" optionLabel="name" optionValue="id"
                                        :labelInOption="'id - name'" 
                                        :labelInValue="'id - name'" :filter="true"
                                        v-model="Estatus"/>
                                </div>
                            </Fieldset>
                            <br>
                            <Fieldset :toggleable="true" legend="Recibos">
                                <div class="p-fluid formgrid grid">
                                    <div class="field col-12 sm:col-9 md:col-12 xl:col-12">
                                        <BasicDatatable @selected="selectItem" :headers="adminHeaders" 
                                            :rowaction="true" :deleteSign="true" :selectionMode="'single'" 
                                            :dataKey="'number'" :rows="entities" @signed="openCancelation"
                                            wrapperClass="field col-12 sm:col-5 md:col-12 xl:col-12"
                                            :key="componentKey" :rowlog="true" @logged="openLog" rowname="Acciones"
                                            :rowemail="true" @emailed="openEmailDialog"/>
                                    </div>
                                </div>
                            </Fieldset>
                            <Dialog
                                v-model:visible="newDialog"
                                :style="{ width: '600px' }"
                                :modal="true">
                                <div v-if="!modal" class="col-12">
                                    <br>
                                    <Fieldset legend="Cancelacion de Recibo">
                                            <div class="p-fluid formgrid grid">
                                                <FormDropdownComplex :wrapperClass="wrapperClass"
                                                label="Motivo de cancelacion" :options="cancellation_reason" optionLabel="name" optionValue="id"
                                                :labelInOption="'id - name'" 
                                                :labelInValue="'id - name'" :filter="true"
                                                v-model="entity.cancelation_reason"/>
                                                <FormInputText
                                                label="Recibo Electronico Relacionado"
                                                :wrapperClass="wrapperClass"
                                                v-model="entity.cancellation_related_receipt" 
                                                @search="(CancelationRelationHelper.visible = true)"
                                                v-if="showRelated==true"
                                                :search="true" :readonly="true"/>
                                                <br>
                                                <br>
                                                <Button label="Porceder con la cancelacion" icon="pi pi-file-export" class="p-button-primary mr-2" @click="cancaelConfirm"/>
                                            </div>
                                    </Fieldset>
                                </div>
                            </Dialog>
                            <Dialog
                                v-model:visible="logDialog"
                                :style="{ width: '1000px' }"
                                :modal="true">
                                <div v-if="!modal" class="col-12">
                                    <br>
                                    <Fieldset legend="Cancelacion de Recibo">
                                        <div class="p-fluid formgrid grid">
                                            <div class="field col-12 sm:col-9 md:col-12 xl:col-12">
                                                <BasicDatatable :headers="LogHeaders" 
                                                    :rows="receiptLogs" wrapperClass="field col-12 sm:col-5 md:col-12 xl:col-12"/>
                                            </div>
                                        </div>
                                    </Fieldset>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </Panel>
            </div>
        </div>
    </div>
</template>
<script>
import FormInputText from "../../../components/general/FormInputText.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";
// import DeleteDialog from "../../../components/general/DeleteDialog.vue";
import FormInputNumber from "../../../components/general/FormInputNumber.vue";
import {
    HeaderGrid,
//     Rule,
//     validate,
    fillObject,
//     Toast,
    ErrorToast,
    satCatalogo,
} from "../../../utilities/General";
import { Customer } from "../../../models/comercial/Customer";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import { ReceiptPayment } from "../../../models/comercial/ReceiptPayment";
import HelperDialog from '../../../components/general/HelperDialog.vue';
import moment from "moment";
import Email from '../../../components/general/EmailForm.vue';
export default {
    mixins: [Session],
    props: {
        modal: null,
        id: null
    },
    components: { Loader,  FormInputText, FormCalendar, BasicFormToolbar, BasicDatatable, FormDropdownComplex, HelperDialog, FormInputNumber, Email },
    data(){
        return{
            entity: new ReceiptPayment(this.session),
            Customers: [],
            entities: [],
            c_Moneda: [],
            STATUS: [],
            initial_date: new Date(),
            final_date: new Date(),
            Customer: null,
            Customer_rfc: null,
            Folio_Dep: null,
            Estatus: null,
            Currency: null,
            Folio: null,
            newDialog: false,
            cancellation_reason: [],
            CancelReceiptPayment: [],
            ReceiptPayments: [],
            related_receipt: null,
            wrapperClass: "field col-12 md:col-12 xl:col-12",
            showRelated: false,
            componentKey: 0,
            logDialog: false,
            receiptLogs: [],
            receipt_to_email: [],
            emailDialog: {
                visible: false,
                html: '',
            },
            adminHeaders: [
                new HeaderGrid('Estatus', 'status', {type: 'component', component:'Tag'}), 
                new HeaderGrid('Folio', 'id'),
                new HeaderGrid('Cliente', 'customer'),
                new HeaderGrid('RFC del Cliente', 'rfc_Customer'), 
                new HeaderGrid('Fecha', 'date', {type: "date"}),
                new HeaderGrid('Moneda', 'currency'),
                new HeaderGrid('T.C.', 'exchange_rate'), 
                new HeaderGrid('Total', 'amount', {type: "currency4"}),
                new HeaderGrid('Folio del Deposito', 'id_payment'),
                //new HeaderGrid('P.U.', 'unit_price', { type: 'currency' })
            ],
            CustomerHeaders: [
                new HeaderGrid('Numero de Cliente', 'id'), 
                new HeaderGrid('Nombre', 'name'),
                new HeaderGrid('RFC del Cliente', 'rfc'),
            ],
            LogHeaders: [
                new HeaderGrid('Folio del Error', 'id'), 
                new HeaderGrid('Codigo de error', 'code'),
                new HeaderGrid('Error ', 'errorMessage'),
                new HeaderGrid('Detalle', 'errorDetail'), 
                new HeaderGrid('Razon de cancelacion', 'cancelation_reason'),
                new HeaderGrid('Persona que ordeno la cancelacion', 'created_by'),
                new HeaderGrid('Fecha del intento de cancelacion', 'created', {type: "date"}),
            ],
            CustomerHelper: {
                visible: false,
            },
            CancelationRelationHelper: {
                visible: false,
            },
        }
    },
    async created (){ 
        this.entity = new ReceiptPayment(this.session);
    },
    async mounted(){
        await this.refresh();
        this.c_Moneda = await satCatalogo(9);
    },
    watch: {
        'entity.cancelation_reason'(newValue){
            if(newValue == 1){
                this.wrapperClass="field col-12 md:col-6 xl:col-6";
                this.showRelated=true;
            }
            else{
                this.entity.cancellation_related_receipt=null;
                this.wrapperClass="field col-12 md:col-12 xl:col-12";
                this.showRelated=false;
            }
        },
    },
    methods: {
        async openCancelation(payload){
            if(payload.status == "En Proceso de Cancelacion"){
                this.$toast.add({
                    severity: "error",
                    summary: "Cancelacíon en proceso",
                    detail: "La cancelacíon ya entro en proceso de aprobacíon",
                    life: 6000,
                });  
            }
            if(payload.status == "Cancelado"){
                this.$toast.add({
                    severity: "error",
                    summary: "Recibo cancelado",
                    detail: "El recibo seleccionado ya fue cancelado",
                    life: 6000,
                });  
            }
            if(payload.cancelation_status != null && payload.cancelation_status != '201' && payload.cancelation_status != '202'){
                this.$toast.add({
                    severity: "error",
                    summary: "Detalle con la cancelacion",
                    detail: "Ocurrio un detalle al momento de realizar la cancelacion, revise el log para mas informacion ", 
                    life: 6000,
                });  
            }
            if(payload.status != "En Proceso de Cancelacion" && payload.status != "Cancelado" && payload.cancelation_status == null){
                this.entity.cancelation_reason=null;
                this.entity.cancellation_related_receipt=null;
                this.newDialog = true;
                try{
                    this.loading = true;
                    this.CancelReceiptPayment = await new ReceiptPayment(this.session).getReceipt({
                        folio: payload.id,
                    });
                    console.log(JSON.stringify(payload))
                    this.ReceiptPayments = await new ReceiptPayment(this.session).getAll();
                    this.ReceiptPayments = this.ReceiptPayments.filter(x => x.id != payload.id && x.id_customer == payload.id_customer && x.status != 'Cancelado' && x.status != 'En Proceso de Cancelacion' && x.cancelation_status == null);
                } catch(error){
                    this.$toast.add(new ErrorToast(error));
                }
                finally{
                    this.loading = false;
                }
            }
        },
        selectNewReceipt(payload){
            this.entity.cancellation_related_receipt = payload.id;
            this.entity.uuid_sustitution = payload.uuid
        },
        async openLog(payload){
            this.receiptLogs = await new ReceiptPayment(this.session).getLog({
                id_receipt_payment: payload.id,
            });
            this.logDialog=true;
        },
        async cancaelConfirm(){
            let cancelation_reason = this.entity.cancelation_reason;
            let cancellation_related_receipt = this.entity.cancellation_related_receipt;
            let uuid_sustitution = this.entity.uuid_sustitution;
            this.entity = fillObject(this.entity, this.CancelReceiptPayment);
            this.entity.cancelation_reason = "0"+cancelation_reason;
            this.entity.cancellation_related_receipt = cancellation_related_receipt;
            this.entity.uuid_sustitution = uuid_sustitution;
            console.log(JSON.stringify(this.entity));
            try{
                if(this.entity.id != null && this.entity.id > 0 && this.entity.id != ''){
                    console.log(JSON.stringify(this.entity.cancelation_reason+", "+ this.entity.uuid_sustitution))
                    await this.entity.cancel({
                        cancelation_reason: this.entity.cancelation_reason,
                        uuid: this.entity.uuid_sustitution ?? null,
                    });
                    this.entities = await new ReceiptPayment(this.session).getAdmin({
                        initial_date: moment(this.initial_date).format(),
                        final_date: moment(this.final_date).format(),
                        Customer: this.Customer ?? '',
                        Customer_rfc: this.Customer_rfc ?? '',
                        Folio_Dep: this.Folio_Dep ?? null,
                        Estatus: this.Estatus ?? null,
                        Currency: this.Currency ?? '',
                        Folio: this.Folio ?? null,
                    });
                    this.$toast.add({
                        severity: "success",
                        summary: "Cancelacion enviada",
                        detail: "Cancelacion enviada con exito",
                        life: 3000,
                    });
                    this.entity = new ReceiptPayment(this.session);
                    this.newDialog = false;
                }
            }
            catch(error){
                this.$toast.add(new ErrorToast(error));
            }
        },
        async openEmailDialog(payload){
            this.receipt_to_email = await new ReceiptPayment(this.session).getReceipt({
                folio: payload.id,
            });
            this.entity = fillObject(this.entity, this.receipt_to_email);
            try {
                await this.entity.downloadPDF();
                // if (!this.entity.id)
                //     throw "No es posible enviar correo, factura no generada";
                // if (!this.entity.is_certificated) {
                //     throw "No es posible enviar correo, factura no certificada";
                // }
                this.emailDialog.visible = true;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        sendedEmail() {
            this.emailDialog.visible = false;
            this.$toast.add({
                severity: 'success',
                summary: 'Factura',
                detail: 'Correo enviado con exito',
                life: 3000,
            });
        },
        errorEmail(error) {
            this.$toast.add(new ErrorToast(error));
        },
        async openTable(){
            this.loading = true;
            try {
                this.entities = await new ReceiptPayment(this.session).getAdmin({
                    initial_date: moment(this.initial_date).format(),
                    final_date: moment(this.final_date).format(),
                    Customer: this.Customer ?? '',
                    Customer_rfc: this.Customer_rfc ?? '',
                    Folio_Dep: this.Folio_Dep ?? null,
                    Estatus: this.Estatus ?? null,
                    Currency: this.Currency ?? '',
                    Folio: this.Folio ?? null,
                });
                this.componentKey += 1;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
            finally{
                this.loading = false;
            }
        },
        async openNew() {
            this.entity = new ReceiptPayment(this.session); 
        },
        selectCustomer(payload){
            this.Customer=payload.name;
            this.Customer_rfc=payload.rfc;
        },
        async refresh() {
            try {
                this.loading = true;
                this.entity = new ReceiptPayment(this.session);
                this.Customers = await new Customer(this.session).all();
                this.STATUS = [{id:1,name:"SIN TIMBRAR"},{id:2,name:"TIMBRADO"} ,{id:3,name:"EN PROCESO DE CANCELACION"} ,{id:4,name:"CANCELADO"}];
                this.cancellation_reason = [{id:1,name:"Con Relacion"},{id:2,name:"Sin Relacion"} ,{id:3,name:"No se llevo a cabo"} ,{id:4,name:"Operacion Nominativa"}];
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
            finally{
                this.loading = false;
            }
        },
    }
};
</script>

<style>
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>